<template>
  <div>
    <div v-if="dataLoaded && show404 === false" class="mobile-padding">
      <div class="quick-notice" v-if="quickNotice" :class="permanentHide">
        <button class="notice-btn" v-on:click="showQuickNotice()">{{translationKeywords['read-imp-notice'] || 'Read important notice'}}</button>
      </div>
      <main id="main-content">
        <section class="usa-hero bg-primary-dark" style="background-image: url('/assets/img/hero.jpg')">
          <div class="grid-container">
            <div class="usa-hero__callout padding-2" style="background-color: transparent; max-width: 100%;">
              <rich-text-renderer :data="pageData.fields.pageHeading"></rich-text-renderer>
              <rich-text-renderer :data="pageData.fields.pageMainDescription"></rich-text-renderer>
            </div>
          </div>
        </section>
        <div :key="i" v-for="(content, i) in pageData.fields.secondSection.content">
          <document-renderer v-if="content.nodeType === 'embedded-entry-block'" :documentId="content.data.target.sys.id"></document-renderer>
          <div class="container more-info-text" v-if="content.nodeType !== 'embedded-entry-block'">
            <rich-text-renderer :data="content"></rich-text-renderer>
          </div>
        </div>

        <div :key="'A'+i" v-for="(ref, i) in pageData.fields.pageHorizontalRefs">
          <div class="row" :class="{ even: i % 2 == 0, odd: i % 2 !== 0 }">
            <document-renderer :documentId="ref.sys.id"></document-renderer>
          </div>
        </div>
        
        <div :hidden="!showModal">
          <document-renderer :key="i" v-for="(gp, i) of pageData.fields.modalPopupRefs" :documentId="gp.sys.id" :additionaldata="{
              calculator: calculator,
              showModal: showModal 
            }"></document-renderer>
        </div>
        </main>
    </div>
    <PageNotFound v-if="dataLoaded && show404 === true" />
  </div>
</template>
<script>
import { 
  getCountryResourceGroup,
  getSystemGlobalResources,
  getUSTDLiveUrlStatus,
  getDataByContentType,
  getTranslationKeywords
} from "@/store/api";
import { urlSlug, urlLangCode, urlCountryCode } from "@/helpers/url";
import PageNotFound from "@/components/PageNotFound";


function outsideMenuClickHandler(event) {
  const isButtonClicked = event.target.closest(".quick-notice"); 
  const isMegaMenuClicked = event.target.closest("#group-fees-dialog"); 
  if (!isButtonClicked && !isMegaMenuClicked) {
    this.permanentHide = 'permanentHide';
  }
}
export default {
  name: "left-menu-and-body-page-template",
  components: { PageNotFound },
  data() {
    return {
      dataLoaded: false,
      pageData: {},
      showModalClass: "",
      host: location.host,
      show404: false,
      showModal: true,
      calculator: this,
      quickNotice: false,
      permanentHide: '',
      translationKeywords:{},
      pageData:{
            fields: {
              modalPopupRefs: null
            }
        }
    };
  },
  mounted() {
      this.$root.$on("modal-popup:show", () =>{
      this.showModal = true;
      this.quickNotice = false;
    });
  },
  created() {  
    document.addEventListener("click", this.outsideMenuClickHandler);
    this.$on("close-modal", () => {
      this.showModal = false;
      this.quickNotice = true;
    });
    if(location.search.includes('nodialog')){
      this.showModal = false;
      this.quickNotice = false;
    }
    const urlSlugText = urlSlug();
    getDataByContentType("nonImmigrantVisaHomeTemplate", urlSlugText, this.$route.params.countrycode, this.$route.params.langcode).then((pageData) => {
      this.dataLoaded = true;
      if (!pageData) {
        this.show404 = true; 
      }

      // Avnish, 04-Sept-2023, Requirement - check and show the country is live or not.
      getCountryResourceGroup(this.$route.params.countrycode).then((res) => {
        if(res?.fields?.resJson) {
          let globalResourceNotLiveArr = res.fields.resJson['non-live-countries'];
          // globalResourceNotLiveArr = [{"country": "de","language": "en","isNonLive": "true" }];
          console.log("globalResourceNotLiveArr:",globalResourceNotLiveArr);
          console.log("route Country and Lang Code:: ", this.$route.params.countrycode, this.$route.params.langcode)
          console.log("host :", this.host);
        if((globalResourceNotLiveArr) && (this.host !='cgistaging.scriptbytes.co.in')){
            let filterCurrentCountryObj = globalResourceNotLiveArr.filter(el => el.country == this.$route.params.countrycode.trim() && ((el.language == this.$route.params.langcode.trim() && (el.isNonLive == 'true' || el.isNonLive == true)) || (!el.language && (el.isNonLive == 'true' || el.isNonLive == true))));
            console.log("filterCurrentCountryObj:", filterCurrentCountryObj);
            if(filterCurrentCountryObj.length > 0) {
              if(filterCurrentCountryObj[0]['isNonLive'] == "true" && this.$route.query.accesstoken && this.$route.query.accesstoken == filterCurrentCountryObj[0]['accesstoken']) {
                console.log("country level token wise accesible for non live country.", this.$route.query.accesstoken);
              }
              else {
                getSystemGlobalResources().then((globalResponse) => {
                  let globalAccessToken = globalResponse?.fields?.resJson?.accesstoken;
                  // globalAccessToken = "20ca9a431341756398632ada6b761820";
                  if(this.$route.query.accesstoken && globalAccessToken == this.$route.query.accesstoken) {
                    console.log("global level token wise accesible for non live country.", this.$route.query.accesstoken);
                  }
                  else {
                    getUSTDLiveUrlStatus(window.location.origin).then((liveUrlStatus) => {
                      console.log("liveUrlStatus:", liveUrlStatus);
                      if(!liveUrlStatus) {
                        this.show404 = true;
                      }
                    });
                  }
                });
              }
            }
          }
        }
        // this.pageData = pageData;
        // this.setPageTitle(this.pageData.fields.pageTitle || "USTravelDocs");
      });

      this.pageData = pageData;
      console.log("pageData: ",pageData);
      this.setPageTitle(this.pageData.fields.pageTitle || "USTravelDocs");
    });
  },
  methods: {
    outsideMenuClickHandler: outsideMenuClickHandler,
    showQuickNotice() {
      this.quickNotice = false;
      this.showModal = true;
      this.$nextTick(() => {
        this.$el.querySelector('.usa-dialog__close').focus();
      });
    }
  },
  beforeCreate() {
    getTranslationKeywords(urlLangCode()).then(
      (keywords) => (this.translationKeywords=keywords)
    );
  },
  destroyed() {
    document.removeEventListener("click", this.outsideMenuClickHandler);
  },
};
</script>
<style lang="scss" scoped>
.more-info-text{
  text-align: center;
  margin-bottom: 6px;
}
.usa-hero__callout {
  ::v-deep h1 {
    font-size: 2.92722rem;
    font-family: Helvetica, Merriweather Web, Georgia, Cambria, Times New Roman, Times, serif;
  }
  ::v-deep p {
    margin: 10px 0;
  }
}
#visa-steps {
  ::v-deep h2 {
    margin-top: 50px;
    font-size: 2.12941rem;
  }
}
.visa-types {
  justify-content: center;
}
::v-deep .green-button a {
  background-color: #2e8540;
  font-family: Helvetica, Helvetica Neue, Helvetica, Roboto, Arial,sans-serif;
  font-size: 23px;
  line-height: 0.93923;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  border-radius: 0.25rem;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  margin-right: 0.5rem;
  padding: 1rem 1.5rem;
  text-align: center;
  text-decoration: none;
  width: auto;
  span {
    display: none;
  }
  &:hover {
    background-color: #2A7A3B;
    color: #FFF;
    span {
      display: none;
    }
  }
}
.usa-dialog__body {
  height: 70%;
}
#dialog-1 {
  display: none;
}
.DispalyNone {
  display: none;
}
.showModal {
  display: block !important;
}
.quick-notice {
  background: #FFF;
  padding: 10px 0;
  width: 192px;
  position: absolute;
  top: 0;
  z-index: 999;
  outline: .25rem solid #2491ff;
  text-align: center;
  .notice-btn {
    background: transparent;
    text-decoration: underline;
    margin: 0;
    border: 0;
    padding:0;
    color: #004370;
    font-size: 1.1rem;
    &:focus,&:active {
      outline: none;
    }
  }
}
.permanentHide{
  display: none;
}

.nonimmigrant-visa {
  .visa-types {
    margin: 0 auto 2.5rem;
    max-width: 64rem;
  }
}
/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 320px) and (max-width: 767px) {
  .usa-hero__callout {
    ::v-deep h1 {
      font-size: 1.95148rem;
    }
    ::v-deep p {
      margin: 15px 0;
    }
  }
  ::v-deep .green-button a {
    margin-bottom: 10px;
    width: 100%;
    padding: 15px 0;
    font-size: 1.3rem;
  }
  #visa-steps {
  ::v-deep h2 {
    font-size: 1.5rem;
    margin: 3rem 0 2rem;
    }
  }
  .mobile-padding {
    .visa-types {
      padding: 0 3rem;
    }
    .usa-hero__callout {
      ::v-deep .visa-types {
        padding: 0;
        display: block;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .usa-hero__callout {
    ::v-deep h1 {
      font-size: 1.95148rem;
    }
    ::v-deep p {
      margin: 15px 0;
    }
  }
  ::v-deep .green-button a {
    margin-bottom: 10px;
  }
  #visa-steps {
  ::v-deep h2 {
    font-size: 1.5rem;
    margin: 3rem 0 2rem;
    }
  }
  .mobile-padding {
    .visa-types {
      padding: 0 3rem;
    }
  }
}
</style>
